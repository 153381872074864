import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/Sidebar';
import Feature from '../components/Feature';

import banner from '../assets/images/banner.jpg';

const sections = [
  { id: 'one', name: 'Inicio' },
  { id: 'two', name: 'Qué hacemos' },
  { id: 'three', name: 'Últimos proyectos' },
  { id: 'four', name: 'Contacto' },
];
const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <script
        src="https://kit.fontawesome.com/3c46186838.js"
        crossorigin="anonymous"
      ></script>
    </Helmet>
    <SideBar sections={sections} />
    <div id="wrapper">
      <div id="main">
        <section id="one">
          <div className="image main" data-position="center">
            <img src={banner} alt="" />
          </div>
          <div className="container">
            <header className="major">
              <h2>AD Ingeniería</h2>
              <p>
                Experiencia y compromiso para sus proyectos de ingeniería de
                aguas y energía.
              </p>
            </header>
          </div>
        </section>

        <section id="two">
          <div className="container">
            <h3>Qué hacemos</h3>
            <ul className="feature-icons">
              <li className="fa-tint">Tratamiento de aguas</li>
              <li className="fa-life-ring">Ingeniería hidráulica</li>
              <li className="fa-bolt">Optimización energética</li>
              <li className="fa-snowflake">Climatización</li>
              <li className="fa-sort-amount-down">Cálculo de estructuras</li>
              <li className="fa-recycle">Medio ambiente</li>
            </ul>
          </div>
        </section>

        <section id="three">
          <div className="container">
            <h3>Últimos proyectos</h3>
            <p></p>
            <div className="features">
              {data.allMarkdownRemark.edges.slice(0, 3).map(({ node }) => (
                <Feature
                  pic={node.frontmatter.imagen}
                  title={node.frontmatter.title}
                >
                  <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
                </Feature>
              ))}
            </div>
            <Link to="/proyectos" className="button primary">
              Ver más
            </Link>
          </div>
        </section>

        <section id="four">
          <div className="container">
            <h3>Contacto</h3>
            <ul className="feature-icons">
              <li className="fa-user">Antonio Donado Campos</li>
              <li className="fa-id-card">Ingeniero Industrial</li>
              <li className="fa-envelope">
                <a href="mailto:ad@adingenieria.net">ad@adingenieria.net</a>
              </li>
              <li className="fa-phone">633 15 05 82</li>
              <li className="fa-linkedin">
                <a href="https://www.linkedin.com/in/antonio-donado-campos-9141a731">
                  Antonio Donado Campos
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <section id="footer">
      <PageFooter />
    </section>
  </Layout>
);

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___fechapublicacion, order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            imagen
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
